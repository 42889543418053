import React, { useState } from 'react'
import "./navbar.css"
import callImage from "../../img/Figma-Banner/Figmabnr1.jpg";
import logo from '../../img/blacklog1.png';
import logo2 from '../../img/blacklogo.png';
import { RxCross1, RxHamburgerMenu } from "react-icons/rx";
import { IoSearchOutline } from "react-icons/io5";
import navimg from "../../../src/img/newimg/Rectangle1.jpg";
import navimg1 from "../../../src/img/newimg/Rectangle2.jpg";
import navimg5 from "../../../src/img/newimg/horizonvilla21.webp";
import navimg3 from "../../../src/img/newimg/horizonvilla37.webp";
import navimg4 from "../../../src/img/newimg/horizonvilla14.jpg";
import navimg2 from "../../../src/img/newimg/horizonvilla40.webp";
import Searchlistheader from '../searchlist/Searchlistheader';
import Rlbutton from '../rlbutton/rlbutton';
import { FaRegCalendarAlt } from "react-icons/fa";
import { PiPhoneCall } from "react-icons/pi";



function Navbar4() {

    const [toggle, setToggle] = useState(false)
    return (
        <section className='navbar4'>
            <div className='container'>
                <div className='navbar-header'>
                    <div className="row">
                        <div className='"col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-2'>
                            <div className='navicon'>
                                <div className='main-icon1' onClick={() => setToggle(!toggle)}><RxHamburgerMenu size={20} /></div>
                            </div>
                        </div>
                        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-10'>
                            <div className='newoogo'>
                                <div>
                                    <a href='/'>
                                        <img src={logo} className="girlcars1" alt="" />
                                    </a>
                                    <a  className="girars1" href='/'>
                                        <img src={logo2} className="logo2" alt="" />
                                    </a>
                                </div>
                            </div></div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6">

                            <div className="navcta">
                                <a href="tel:+91-9878908399"><span className='myphone'><PiPhoneCall />+91-9878908399</span></a>
                                {/* <a href="/Register" className=""> Register</a>
                               
                                <a href="/login" className=""> login</a> */}
                                {/* <Rlbutton /> */}

                                {/* <Searchlistheader /> */}

                            </div>

                        </div>
                    </div>
                </div>

                {
                    toggle && <div className='togglediv'>
                        <div className="container ">
                            <div className='navbarpart'>
                                <div className="row">
                                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-6">
                                        <div>
                                            <a href='/'>
                                                <img src={logo} className="girlcars1" alt="" />
                                                <img src={logo2} className="blacklogo20" alt="" />
                                          
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-6">
                                        <div className="icon"><RxCross1 style={{ cursor: "pointer" }} size={30} onClick={() => setToggle(!toggle)} /></div>
                                    </div>
                                </div>
                            </div>
                            <div className='imagespart'>
                                <div className="row">
                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12">
                                        <ul className="homebar">
                                            <li>
                                                <a href="/stays" className="btn-style">Villa Collections</a>
                                            </li>
                                            <li>
                                                <a href="/About" className="btn-style">About</a>
                                            </li>

                                            <li>
                                                <a href="/special-events" className="btn-style">Special Events</a>
                                            </li>
                                            <li>
                                                <a href="/promotions" className="btn-style">Promotions</a>
                                            </li>
                                            {/* <li>
                                                <a href="#" className="btn-style">The Gallery</a>
                                            </li> */}
                                            <li>
                                                <a href="/contact" className="btn-style">Contact</a>
                                            </li>
                                            {/* <li>
                                                <a href="#" className="btn-style">Offers</a>
                                            </li> */}
                                          
                                        </ul>
                                    </div>
                                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12">
                                        <div className='images2'>
                                            <div className="row">

                                                <div className="col-6">

                                                    <div className="chatmsg">
                                                        <img src={navimg} className="chatmsg-img" alt="Chat" />
                                                    </div>
                                                </div>

                                                <div className="col-6">
                                                    <div className="chatmsg">
                                                        <img src={navimg1} className="chatmsg-img" alt="Chat" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="chatmsg">
                                                        <img src={navimg2} className="chatmsg-img" alt="Chat" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="chatmsg">
                                                        <img src={navimg3} className="chatmsg-img" alt="Chat" />
                                                    </div>
                                                </div>
                                            </div>



                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="chatmsg">
                                                        <img src={navimg4} className="chatmsg-img" alt="Chat" />
                                                    </div>
                                                </div>

                                                <div className="col">
                                                    <div className="chatmsg">
                                                        <img src={navimg5} className="chatmsg-img" alt="Chat" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                }
            </div>
        </section>
    )
}

export default Navbar4;
