
import Stayssections from "../../components/Homestay-components/Stayssections";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Navbarinner from "../../components/navbar/Navbarinner.jsx";

const Villacollection = () => {
  const backgroundImg = "/assets/img//bg/villacollection.webp";
  const pageTitle = "Villa Collections ";
  const pageSubheading = "Only the finest homes make it to our collection. ";
  return (

    // style={{
    //   backgroundImage: "url(" + Figmabnr6 + ")",
    // }}
    <>
    {/* <div className="vill">
          <Breadcrumb
        backgroundImg={backgroundImg}
        title={pageTitle}
        subheading={pageSubheading}
      />
        </div> */}
   <Navbarinner />
      {/* <Staysbnner /> */}
      <Stayssections />
  
      </>
  );
};

export default Villacollection;
