import { DateRange } from "react-date-range";
import { useContext, useState, useEffect, useRef } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { SearchContext } from "../../context/SearchContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faCalendarDays, faUser } from "@fortawesome/free-solid-svg-icons";


import Footer1 from "../footer/Footer";

const SearchList3 = () => {
  const [destination, setDestination] = useState("");
  const [openDate, setOpenDate] = useState(false);
  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [details, setDetails] = useState(null);
  const [openDestinationDropdown, setOpenDestinationDropdown] = useState(false);
  const destinations = [
    {
      city: "Mussoorie",
    },
    {
      city: "Dalhousie",
    },
    {
      city: "Landour",
    },

  ];

  const handleDestinationClick = () => {
    setOpenDestinationDropdown(true);
  };

  const handleDestinationSelect = (selectedDestination) => {
    setDestination(
      `${selectedDestination.city} `
    );
    setOpenDestinationDropdown(false);

    setOptions((prevOptions) => ({
      ...prevOptions,
      adult: 1,
    }));

    // Update details based on the selected destination
    setDetails(selectedDestination);
  };

  const handleDestinationChange = (e) => {
    const inputValue = e.target.value;
    const sentenceCaseInput = inputValue
      .toLowerCase()
      .replace(/\b\w/g, (c) => c.toUpperCase());

    const matchedDestination = destinations.find(
      (dest) => dest.city.toLowerCase() === sentenceCaseInput
    );

    setDestination(
      matchedDestination
        ? `${matchedDestination.city}`
        : sentenceCaseInput
    );
  };

  const [openOptions, setOpenOptions] = useState(false);
  const [options, setOptions] = useState({
    adult: 1,
    children: 0,
    room: 1,
  });

  const navigate = useNavigate();

  const handleOption = (name, operation) => {
    let maxAdults = 8;

    if (details) {
      const guestLimit = details.details.match(/Max (\d+) Guests/);
      if (guestLimit) {
        maxAdults = parseInt(guestLimit[1], 10);
      }
    }

    if (operation === "i" && options[name] < maxAdults) {
      setOptions((prev) => ({
        ...prev,
        [name]: prev[name] + 1,
      }));
    } else if (operation === "d" && options[name] > 1) {
      setOptions((prev) => ({
        ...prev,
        [name]: prev[name] - 1,
      }));
    }
  };

  const { dispatch } = useContext(SearchContext);
  const [endDateSelected, setEndDateSelected] = useState(false);

  const handleDateChange = (item) => {
    const { startDate, endDate } = item.selection;

    if (!endDateSelected) {
      setDates([
        {
          startDate,
          endDate,
          key: "selection",
        },
      ]);
    } else {
      setDates([
        {
          startDate,
          endDate,
          key: "selection",
        },
      ]);
      setOpenDate(false);
    }

    setEndDateSelected(!endDateSelected);
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      const dropdown = document.querySelector(".destinationDropdown");
      const input = document.querySelector(".headerSearchInput");

      if (
        dropdown &&
        !dropdown.contains(e.target) &&
        input &&
        !input.contains(e.target)
      ) {
        setOpenDestinationDropdown(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [openDestinationDropdown]);

  const handleSearch = () => {
    // Trim the destination before checking and dispatching
    const trimmedDestination = destination.trim().toLowerCase();
  
    if (
      trimmedDestination &&
      dates[0].startDate &&
      dates[0].endDate &&
      (options.adult > 0 || options.children > 0 || options.room > 0)
    ) {
      dispatch({
        type: "NEW_SEARCH",
        payload: { destination: trimmedDestination, dates, options },
      });
      navigate("/stays", { state: { destination: trimmedDestination, dates, options } });
      window.scrollTo(0, 0);
    } else {
      alert("Please fill in all required fields.");
    }
  };
  

  
  const datePickerRef = useRef();
  const optionsRef = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setOpenDate(false);
      }
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setOpenOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [datePickerRef]);
  return (
    <div className="headerSearch1">
      <div className="search-outer1">
        <div className="headerSearchItem1" id="detail01">
        <p className="pt1">Cottages/Villas/Chalets</p>
          <span className="newone">
            {/* <img src={locate} className="locate2" alt="" /> */}
            <FontAwesomeIcon icon={faLocationDot} className="search-icon" /> 
         
          <input
            type="text"
            placeholder="Pick a destination"
            className="headerSearchInput"
            id="transparent-search"
            onClick={handleDestinationClick}
            onChange={handleDestinationChange}
            value={destination}
          />
           </span>

          {/* Destination dropdown */}
          {openDestinationDropdown && (
            <div className="destinationDropdown">
              {/* <h3 className="searches-rec">Locations</h3> */}
              {destinations.map((dest) => (
                <div
                  key={dest.city}
                  className="destinationOption"
                  onClick={() => handleDestinationSelect(dest)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-geo-alt"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                  </svg>
                  <span className="dest-name">{dest.city}</span>
                  <br />
                  {/* <span className="dest-details">({dest.details})</span> */}
                </div>
              ))}
            </div>
          )}
          <span className="detail-prop">
            {/* {details && (
            <p className="details">
              {details.details}
            </p>
          )} */}
          </span>
        </div>
        <div className="headerSearchItem1">
          <div className="headerSearchItem4  bb123">
          <p className="pt1"> Check in - Check out </p> 
            <span>
              {/* <img src={check1} className="locate" alt="" /> */}
      
          <span 
              onClick={() => setOpenDate(!openDate)}
              className="newone"
            >    <FontAwesomeIcon icon={faCalendarDays} className="search-icon" />
              {`${format(dates[0].startDate, "MM/dd/yyyy")} - ${format(
                dates[0].endDate,
                "MM/dd/yyyy"
              )}`}
            </span>
            </span>
         
            
       
          </div>
          <div className="headerSearchItem1">
            {openDate && (
              <div ref={datePickerRef}>
                <DateRange
                  editableDateInputs={true}
                  onChange={handleDateChange}
                  moveRangeOnFirstSelection={false}
                  ranges={dates}
                  className="date"
                  minDate={new Date()}
                />
              </div>
            )}
          </div>
        </div>
        <div className="headerSearchItem">
          <div className="headerSearchItem2">
            <div className="headerSearchItem3  guestt">
            <p className="pt1"> Guests </p> 
            
              <span
                onClick={() => setOpenOptions(!openOptions)}
              className="newone"
              > <FontAwesomeIcon icon={faUser} className="search-icon" /> 
              {`${options.adult} adult · ${options.children} children `}</span>
            </div>
            <div className="headerSearchItem3">
              {openOptions && (
                <div className="options" ref={optionsRef}>
                  <div className="optionItem">
                    <p className="optionText">Adults</p>
                    <div className="optionCounter">
                      <button
                        disabled={options.adult <= 1}
                        className="optionCounterButton"
                        onClick={() => handleOption("adult", "d")}
                      >
                        -
                      </button>
                      <span className="optionCounterNumber">
                        {options.adult}
                      </span>
                      <button
                        className="optionCounterButton"
                        onClick={() => handleOption("adult", "i")}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="optionItem">
                    <p className="optionText">Children</p>
                    <div className="optionCounter">
                      <button
                        disabled={options.children <= 0}
                        className="optionCounterButton"
                        onClick={() => handleOption("children", "d")}
                      >
                        -
                      </button>
                      <span className="optionCounterNumber">
                        {options.children}
                      </span>
                      <button
                        className="optionCounterButton"
                        onClick={() => handleOption("children", "i")}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  {/* <div className="optionItem">
                  <span className="optionText">Room</span>
                  <div className="optionCounter">
                    <button
                      disabled={options.room <= 1}
                      className="optionCounterButton"
                      onClick={() => handleOption("room", "d")}
                    >
                      -
                    </button>
                    <span className="optionCounterNumber">
                      {options.room}
                    </span>
                    <button
                      className="optionCounterButton"
                      onClick={() => handleOption("room", "i")}
                    >
                      +
                    </button>
                  </div>
                </div> */}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <div className="headerSearchItem">
       
        </div> */}
        <button className="headerBtn1" onClick={handleSearch}>
      Book Now
        </button>
      </div>
    </div>
  );
};

export default SearchList3;
