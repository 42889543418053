import React from "react";
import ns4 from "../../img/ns4.webp";
import ns3 from "../../img/ns3.webp";
import ns2 from "../../img/ns2.webp";
import ns1 from "../../img/ns1.webp";
import stay1 from "../../img/stay1.webp";
import stay2 from "../../img/stay2.webp";
import stay3 from "../../img/stay3.webp";
import stay4 from "../../img/stay4.webp";
import stay5 from "../../img/stay5.webp";
import stay6 from "../../img/stay6.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import { FaArrowRightLong } from "react-icons/fa6";
import { IoLocationOutline } from "react-icons/io5";


import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const Allstayslider = () => {
    return (
        <section className="hp-room-section">
            <div className="hp-room-items">
                <div className="container">

                    <div className="Industriesserve-wrapper ">
                        <div className="Industriesserve-slider">
                            <Swiper
                                spaceBetween={30}
                                slidesPerView={3}
                                autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: true,
                                }}
                                loop={true}
                                navigation={{
                                    nextEl: ".custom-next",
                                    prevEl: ".custom-prev",
                                }}
                                modules={[Autoplay, Navigation]}
                                breakpoints={{
                                    320: {
                                        slidesPerView: 1,
                                    },
                                    640: {
                                        slidesPerView: 2,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                    },
                                    1400: {
                                        slidesPerView: 4,
                                    },
                                    1920: {
                                        slidesPerView: 4,
                                    },
                                }}
                            >

                                <SwiperSlide>
                                <div className="all-singal">
                                    <img src={stay1} alt="img" className="all-singal-img"/>
                                    <div className="content-bv">
                                    <h3><a href="/stays/the-si-cottage">The SI Cottage</a></h3>
                                    <p className="content-loca"><span><IoLocationOutline /></span>Mussoorie, Uttrakhand, India</p>
                                    <hr />
                                    <div className="pricedata">
                                        <p>  ₹ 19,000<span>/night onwards</span></p>
                                       <span className="icon-svg"><a href="/stays/the-si-cottage"><FaArrowRightLong /></a></span>
                                    </div>
                                    </div>
                                </div>
                               </SwiperSlide>
                               <SwiperSlide>
                                <div className="all-singal">
                                    <img src={stay2} alt="img" className="all-singal-img"/>
                                    <div className="content-bv">
                                    <h3><a href="/stays/the-horizon-villa">The Horizon Villa</a></h3>
                                    <p className="content-loca"><span><IoLocationOutline /></span>Mussoorie, Uttrakhand, India</p>
                                    <hr />
                                    <div className="pricedata">
                                        <p>₹ 31,000 <span>/night onwards</span></p>
                                       <span className="icon-svg"><a href="/stays/the-horizon-villa"><FaArrowRightLong /></a></span>
                                    </div>
                                    </div>
                                </div>
                               </SwiperSlide>
                               <SwiperSlide>
                                <div className="all-singal">
                                    <img src={stay3} alt="img" className="all-singal-img"/>
                                    <div className="content-bv">
                                    <h3><a href="/stays/the-silverton-chalet">The Silverton Chalet</a></h3>
                                    <p className="content-loca"><span><IoLocationOutline /></span>Mussoorie, Uttrakhand, India</p>
                                    <hr />
                                    <div className="pricedata">
                                        <p>  ₹ 21,000 <span>/night onwards</span></p>
                                       <span className="icon-svg"><a href="/stays/the-silverton-chalet"><FaArrowRightLong /></a></span>
                                    </div>
                                    </div>
                                </div>
                               </SwiperSlide>
                               <SwiperSlide>
                                <div className="all-singal">
                                    <img src={stay4} alt="img" className="all-singal-img"/>
                                    <div className="content-bv">
                                    <h3><a href="/stays/the-log-house">The Log House</a></h3>
                                    <p className="content-loca"><span><IoLocationOutline /></span>Mussoorie, Uttrakhand, India</p>
                                    <hr />
                                    <div className="pricedata">
                                        <p>  ₹ 8,000 <span>/night onwards</span></p>
                                       <span className="icon-svg"><a href="/stays/the-log-house"><FaArrowRightLong /></a></span>
                                    </div>
                                    </div>
                                </div>
                               </SwiperSlide>
                               <SwiperSlide>
                                <div className="all-singal">
                                    <img src={stay5} alt="img" className="all-singal-img"/>
                                    <div className="content-bv">
                                    <h3><a href="/stays/the-pink-magnolia-cottage">The Pink Magnolia Cottage</a></h3>
                                    <p className="content-loca"><span><IoLocationOutline /></span>Landour, Mussoorie, Uttarakhand</p>
                                    <hr />
                                    <div className="pricedata">
                                        <p>  ₹ 24,000 <span>/night onwards</span></p>
                                       <span className="icon-svg"><a href="/stays/the-pink-magnolia-cottage"><FaArrowRightLong /></a></span>
                                    </div>
                                    </div>
                                </div>
                               </SwiperSlide>
                               <SwiperSlide>
                                <div className="all-singal">
                                    <img src={stay6} alt="img" className="all-singal-img"/>
                                    <div className="content-bv">
                                    <h3><a href="/stays/the-silver-doon">The Silver Doon</a></h3>
                                    <p className="content-loca"><span><IoLocationOutline /></span>Mussoorie, Uttrakhand, India</p>
                                    <hr />
                                    <div className="pricedata">
                                        <p>  ₹ 15,000 <span>/night onwards</span></p>
                                       <span className="icon-svg"><a href="/stays/the-silver-doon"><FaArrowRightLong /></a></span>
                                    </div>
                                    </div>
                                </div>
                               </SwiperSlide>
                              </Swiper>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default Allstayslider;
