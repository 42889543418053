import Footer2 from "../../components/footer/Footer2";
import Aboutbanner from "../../components/Homebanner/Aboutbanner";
import family from "../../img/family.png";
import leaves from "../../img/leaves.png";
import homestay from "../../img/homestay.png";
import Testimonials from "../../components/newsite/testimonials";
import Navbar2 from "../../components/navbar/Navbar2";
import Instapost from "../../components/newsite/instapost";
import rightpick2 from "../../img/facilities-pics/rightpick2.png";
import Newheader from "../../components/navbar/Newheader";
import Review2slider from "../../components/newsite/Review2slider";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import GardenCottage from "../../img/GardenCottage.webp";
import CountUp from 'react-countup';
import Navbar4 from "../../components/navbar/Navbar4";

const About = () => {
  const backgroundImg = "/assets/img//bg/about.webp";
  const pageTitle = "About Us";
  const pageSubheading = "Explore our finest collection of lavish villas";
  
  return (
    <>
  <div className="main-header-2">
    <Navbar4 />
  </div>
    <div className="abt">
      <Breadcrumb
        backgroundImg={backgroundImg}
        title={pageTitle}
        subheading={pageSubheading}
      />
      {/* <TransparentNavbar /> */}
      {/* <Homeheader /> */}
      {/* <Navbar /> */}
      {/* <Aboutbanner /> */}
      <section className="magnificent">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-md-6 col-12">
              <div className="abht-light">
                <h2>Who We Are </h2>
                <div className="description-heading">
                <p className="hospitality">Welcome to So Many Mornings, where every stay feels like home, yet with the magic of discovery. While we're just hitting our stride, our passion for exceptional hospitality is anything but new. This is the start of an exciting journey, and we can’t wait to have you along for the ride. </p>
                <p className="hospitality">We believe in more than just offering a roof over your head – we offer the gateway to a new way of exploring. Our handpicked rentals combine luxury with impeccable service and top-notch amenities. Whether you're looking for a cozy retreat to recharge or a tranquil escape to break away from the ordinary, our properties are as unique as the destinations themselves. </p>
                </div>
               </div>
            </div>
            <div className="col-xxl-6 col-md-6 col-12 just" >
            <img src={rightpick2} className="rightpick" alt="" />
            </div>
          </div>
        </div>
      </section>
      {/* <section className="abt-us">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-md-6 col-12">
            <img src={rightpick2} className="rightpick" alt="" />
            </div>
            <div className="col-xxl-6 col-md-6 col-12">
            <div className="abht-light">
              <h2>Transforming Staycations Into Unforgettable Experiences </h2>
              <p className="hospitality">With each step of our journey, our promise to add that personal touch becomes stronger. From custom recommendations to making booking a breeze, we’re not just about a bed and breakfast – we’re about making your stay worth remembering. </p>
              <p className="hospitality"> We don’t do average. The moment you walk through our doors, you’ll find not just a place to stay, but luxury homes designed to make you feel cherished and cared for. Come, be part of our story, and let us make your next escape not just a vacation but an unforgettable experience.</p>

            </div>
            </div>
          </div>
        </div>
      </section> */}
       
      <section className="Aboutsections1">
        <div className="container">
          <div className="row" id="abtrow3">
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 just">
              <img src={GardenCottage} className="GardenCottage" alt="" />
            </div>
            <div className="col-xxl-6-col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="colla-abt">
                <div className="style_new sc-heading article_heading ">
                  <div className="content-heading">
                    <h2 >Transforming Staycations Into Unforgettable Experiences</h2>
                    <div className="description-heading">
                    <p className="hospitality">
                    With each step of our journey, our promise to add that personal touch becomes stronger. From custom recommendations to making booking a breeze, we’re not just about a bed and breakfast – we’re about making your stay worth remembering. 
                      </p>
                      <p className="hospitality"> 
                      We don’t do average. The moment you walk through our doors, you’ll find not just a place to stay, but luxury homes designed to make you feel cherished and cared for. Come, be part of our story, and let us make your next escape not just a vacation but an unforgettable experience.
                       </p>
                    </div>
                  </div>

                  <div className="button-heading">
                    <a href="/contact">Contact us</a>
                  </div>
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </section>
      <section className="commitment">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="exciting">
                <h5 className="new-sub-heading">Why choose us </h5>
                <h2 >
                Because We Redefine Ordinary Stays 
                </h2>
              </div>
            </div>
          </div>
          <div className="row" id="adventures">
            <div className="col-xxl-4 col-xl-4 col-md-4 col-12">
              <div className="whychoosebox">
                <img src={leaves} className="leaves" alt="" />
                <h3 className="livee">Only The Finest Homes</h3>
                <p>
                We handpick homes in the most breathtaking destinations and pour our hearts into making them magical so you can leave your worries behind. 
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-md-4 col-12">
              <div className="whychoosebox1">
                <img src={homestay} className="leaves" alt="" />
                <h3 className="livee">High-Grade Amenities</h3>
                <p>
                From cozy spots to unwind to high-speed WiFi and smart TVs, you’ll never be at a loss for ways to relax and stay connected. 

                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-md-4 col-12">
              <div className="whychoosebox2">
                <img src={family} className="leaves" alt="" />
                <h3 className="livee">Well-Cleaned Spaces</h3>
                <p>
                We promise you'll never check into a property with leftover ketchup or crumbs in the corners. And when it’s time to check out, we won’t leave you with a list of chores either. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="aboutcta">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-md-12">
              <h5 className="new-sub-heading">Step into luxury living </h5>
              <h2 className="cap-heading">
                So Many Mornings offers a curated collection of premium
                accommodations nestled amid magnificent views that evoke a sense
                of luxury and timeless aesthetics.{" "}
              </h2>
              <div className="timeless">
                <a className="luxury" href="/villa-collections">
                  EXPLORE NOW
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="client-reviews">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-md-12">
              <h5 className="new-sub-heading">
                Delighted words that keep our wings soaring
              </h5>
              <h2 className="new-heading">Rave reviews </h2>
            </div>
          </div>
        </div>
      </section> */}
      {/* <Testimonials />
      <Instapost /> */}

      {/* <section className="lastcta">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-12 col-md-12 col-12">
              <div className="outboxer">
                <h5 className="new-sub-heading">Book your getaway now! </h5>
                <h3 className="new-heading-2">
                  Let us be your host as you create cherished memories away
                  from the hustle and bustle. Book your stay with us and open
                  doors to a world of luxury, comfort and heartfelt hospitality.{" "}
                </h3>
                <div className="Proding2 mt-4">
                  <a href="/Register" className="explore-button1">
                    SIGN UP <span className="arrow1">→</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="lastcta">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-12 col-md-12 col-12">
              <div className="outboxer">
                <h5 className="new-sub-heading">Book Your Dream Escape!  </h5>
                <h3 className="new-heading-2">
                Getting bored with the ordinary and craving a change of scenery? It’s time to book the getaway you’ve been eyeing. Whether you’re looking for a cozy cottage, a lavish villa, or a gorgeous chalet, we’ve got the picture-perfect home for your dream holiday.
                </h3>
                <div className="Proding2 mt-4">
                    <a href="/Register" className="explore-button1">
                  Sign Up <span className="arrow1">→</span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    </>
  );
};

export default About;
