
import Offerpagesection from "../../components/Homestay-components/Offerpagesection";
import Faqs from "../../components/Homestay-components/Faqs";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Navbar4 from "../../components/navbar/Navbar4";


const Promotions = () => {
  const backgroundImg = "/assets/img//bg/Offer.webp";
  const pageTitle = "Exclusive Offers & Discounts";
  const pageSubheading = "Grab our best offers before they’re gone.";

  return (
    
    <div>
    <div className="main-header-2">
    <Navbar4 />
  </div>
      <Breadcrumb 
      backgroundImg={backgroundImg}
      title={pageTitle}
      subheading={pageSubheading}
    />

      <Offerpagesection />
      <Faqs />
      <section className="lastcta">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-12 col-md-12 col-12">
              <div className="outboxer">
                <h5 className="new-sub-heading">Book Your Dream Escape!  </h5>
                <h3 className="new-heading-2">
                Getting bored with the ordinary and craving a change of scenery? It’s time to book the getaway you’ve been eyeing. Whether you’re looking for a cozy cottage, a lavish villa, or a gorgeous chalet, we’ve got the picture-perfect home for your dream holiday.
                </h3>
                <div className="Proding2 mt-4">
                    <a href="/Register" className="explore-button1">
                    Sign Up<span className="arrow1">→</span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Promotions;
