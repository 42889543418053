import React, { useState, useEffect } from "react";
import image1 from '../../img/bn01.webp';
import image2 from '../../img/bn02.webp';
import image3 from '../../img/bn03.webp';
import image4 from '../../img/bn04.webp';
import image5 from '../../img/bn05.webp';
import image6 from '../../img/bn06.webp';
import image7 from '../../img/bn07.webp';
import image8 from '../../img/bn08.webp';
import SearchList3 from "../searchlist/searchlist3";

const SliderProperties = () => {
  const images = [
  `url(${image3})`,
     `url(${image4})`,
    `url(${image1})`,
    `url(${image2})`,
   `url(${image5})`,
    `url(${image6})`,
    `url(${image7})`,
    `url(${image8})`,

  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [images.length]);

  return (
    <>

      <section className="alSliderProperties">
        <div className="slider-container">
          {images.map((image, index) => (
            <div
              key={index}
              className={`slider-background ${currentIndex === index ? "active" : ""}`}
              style={{
                backgroundImage: image,
              }}
            ></div>
          ))}
          {/* <div className="slider-text">
          <div className="mybnr1"> 
            <h3> Stay in India’s most luxurious homes</h3>
            <div className="bnr-from">
              <SearchList3 />
            </div>
          </div>
        </div> */}
        </div>
      </section>
      <section className="newform-bottom">
        <div className="container">
          <div className="mybnr1">
           <SearchList3 />
          </div>
        </div>
      </section>
  
    </>
  );
};

export default SliderProperties;
