import React from "react";
import doulpick1 from "../img/doulpick1.jpg";
import graphic from "../img/graphic.png";

import Tagreel2 from "../img/collaboration-picks/Tagreel2.mp4";
import Tagreel3 from "../img/collaboration-picks/Tagreel3.mp4";
import Tagreel4 from "../img/collaboration-picks/Tagreel4.mp4";

import doulpick2 from "../img/doulpick2.jpg";
import Tagsgallery from "../components/newsite/Tagsgallery";
import Review2slider from "../components/newsite/Review2slider";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Navbar4 from "../components/navbar/Navbar4";


const Collaborations = () => {
  const backgroundImg = "/assets/img//bg/specialevent.webp";
  const pageTitle = "Special Events";
  const pageSubheading = "Birthdays, anniversaries, reunions, and more – we’ve got it all covered.";

  return (
    <>
     <div className="main-header-2">
    <Navbar4 />
  </div>
    <div className="abt">
    <Breadcrumb
      backgroundImg={backgroundImg}
      title={pageTitle}
      subheading={pageSubheading}
    />

      {/* <TransparentNavbar /> */}
      {/* <section className="Collabrtion">
        <Collaborationbnner />
      </section> */}
      <section className="abt-collab">
        <div className="container">
          <div className="row">
            <div
              className="col-xxl-6-col-xl-6 col-lg-6 col-md-12 col-12"
              id="airy"
            >
              <div className="double-imge">
                <div className="row">
                  <div class="image-left col-md-6 col-sm-6">
                    <img src={doulpick1} className="doulpick1" alt="" />
                  </div>
                  <div class="image-right col-md-6 col-sm-6">
                    <img src={doulpick2} className="doulpick1" alt="" />
                  </div>
                </div>
              </div>
              <div class="image_background">
                <img src={graphic} className="graphic" alt="" />
              </div>
            </div>
            <div className="col-xxl-6-col-xl-6 col-lg-6 col-md-12 col-12">
              <div className="colla-abt">
                <div className="style_new sc-heading article_heading ">
                  <div className="content-heading">
                  {/* <h5 className="new-sub-heading">Collaboration</h5> */}
                    <h2 className="new-heading">
                    Your Memorable Moments Deserve A Beautiful Backdrop
                    </h2>
                    <div className="description-heading">
                    
                      <p className="Coast">
                      Your special events mark significant milestones in life, so why not celebrate them in a truly unforgettable way? Whether you’re ringing in a milestone birthday, toasting a big anniversary, or gathering your closest friends for a long-overdue reunion, make it memorable in our fabulous homes that are exclusively yours. 
                      </p>
                    </div>
                  </div>
              
                  <div className="button-heading">
                    <a href="/villa-collections">Check out our luxury stays</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Tagsgallery />

      <section className="celebrity-visit">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
              <h5 className="new-sub-heading">
              Influencer-Favorite Getaways 
              </h5>
              <h2 className="new-heading">Top Influencers Who Gave Us A Shoutout</h2>
            </div>
            <div className="row" id="exclus00">
              <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                <div className="Trireel">
                  <video
                    controls
                    autoPlay={"autoplay muted"}
                    preLoad="auto"
                    loop
                    id="pseudo-partial-border"
                  >
                    <source src={Tagreel3} type="video/mp4" />
                  </video>
                </div>
              </div>
              {/* <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-12">
                <div className="Trireel">
                  <video controls loop id="pseudo-partial-border">
                    <source src={Tagreel1} type="video/mp4" />
                  </video>
                </div>
              </div> */}
              <div
                className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12"
                id="singlereel"
              >
                <div className="Trireel">
                  <video controls loop id="pseudo-partial-border">
                    <source src={Tagreel2} type="video/mp4" />
                  </video>
                </div>
              </div>

              <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                <div className="Trireel">
                  <video controls loop id="pseudo-partial-border">
                    <source src={Tagreel4} type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
     
{/* 
      <section className="lastcta">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-12 col-md-12 col-12">
              <div className="outboxer">
                <h5 className="new-sub-heading">Book your getaway now! </h5>
                <h3 className="new-heading-2">
                  Let us be your host as you create cherished memories away
                  from the hustle and bustle. Book your stay with us and open
                  doors to a world of luxury, comfort and heartfelt hospitality.{" "}
                </h3>
                <div className="Proding2 mt-4">
                    <a href="/Register" className="explore-button1">
                    SIGN UP <span className="arrow1">→</span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

    </div>
    </>
  );
};

export default Collaborations;
