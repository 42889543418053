import { useEffect } from "react";

const Payment = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const handlePayment = () => {
    if (typeof window.Razorpay === "undefined") {
      console.error("Razorpay SDK not loaded");
      return;
    }

    const options = {
      key: "rzp_test_J9wxk5gscTXPA3",
      amount: 10000, // amount in paise (100 INR)
      currency: "INR",
      name: "So Many Mornings",
      description: "Cottages/Villas/Chalets",
      handler: (response) => {
        console.log(response);
        alert("Payment Successful!");
      },
      prefill: {
        name: "John Doe",
        email: "johndoe@example.com",
        contact: "9999999999",
      },
      theme: {
        color: "#F37254",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  // return <button onClick={handlePayment}>Pay Now</button>;

  return <button onClick={handlePayment} className="explore-button1">
  Book Now <span className="arrow1">→</span>
  </button>;

};

export default Payment;
