import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Footer1 from "../components/footer/Footer";
import Homeheader from "../components/navbar/Homeheader";
import TransparentNavbar from "../components/navbar/Navbar";
import Navbar from "../components/navbar/Navbar";
import Navbar2 from "../components/navbar/Navbar2";
import Footer2 from "../components/footer/Footer2";
import Newheader from "../components/navbar/Newheader";
import Navbar4 from "../components/navbar/Navbar4";

const Refundpolicy = () => {
  const backgroundImg = "/assets/img//bg/inrbnr.webp";
  const pageTitle = "Refund Policy";
  // const pageSubheading =
  //   " Discover what personal information we collect and how we use it. ";

  return (
    <div>
   <div className="main-header-2">
    <Navbar4 />
  </div>
      {/* <TransparentNavbar /> */}
      {/* <Homeheader /> */}
      <Breadcrumb
        backgroundImg={backgroundImg}
        title={pageTitle}
        // subheading={pageSubheading}
      />
      <section className="privacypolicy">
        <div className="container">
          <div className="row text-center">
            <h2>Refund Policy</h2>
            <div className="out-box-content">
              <p>For cancellations made more than 7 days before the check-in date, guests are eligible for a refund. There are no refunds <br className="d-none d-lg-block" />for bookings cancelled 7 days or fewer before the check-in date. Refunds will be processed to the original payment method <br className="d-none d-lg-block" />within 5-10 business days after the cancellation request.</p>
                          
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Refundpolicy;
