import "./list.css";
import { useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { format } from "date-fns";
import { DateRange } from "react-date-range";
import SearchItem from "../../components/searchItem/SearchItem";
import useFetch from "../../hooks/useFetch";
import BigSlider from "../../components/searchlist/wowitsnotworking";
import Updatedpropertyslider from "../../components/my-components/Updated-property-slider";
import Allstayslider from "../../components/my-components/Allstayslider";
import Navbarinner from "../../components/navbar/Navbarinner";

const List = () => {
  const backgroundImg = "/assets/img//bg/inrbnr.webp";
  const pageTitle = "Reserve Your Stay";
  const pageSubheading = "Discover the exciting adventures that await you.";
  const location = useLocation();
  const [checkedAmenities, setCheckedAmenities] = useState([]);
  const [destination, setDestination] = useState(
    location.state?.destination || "mussoorie"
  );
  const [dates, setDates] = useState(location.state?.dates || []);
  const [openDate, setOpenDate] = useState(false);
  const [options, setOptions] = useState(location.state?.options || {});
  const [min, setMin] = useState(4000);
  const [max, setMax] = useState(undefined);
  const [roomsmax, setRoomsmax] = useState(undefined);
  const [guestsmax, setGuestsmax] = useState(undefined);

  const [details, setDetails] = useState(null);
  const [openDestinationDropdown, setOpenDestinationDropdown] = useState(false);
  const destinations = [
    { city: "mussoorie" },
    { city: "dalhousie" },
    { city: "landour" },
  ];

  // Create a ref to the dropdown
  const dropdownRef = useRef(null);

  const handleDestinationClick = () => {
    setOpenDestinationDropdown(true);
  };

  const handleDestinationSelect = (selectedDestination) => {
    setDestination(`${selectedDestination.city}`);
    setOpenDestinationDropdown(false);
    setOptions((prevOptions) => ({
      ...prevOptions,
      adult: 1,
    }));
    setDetails(selectedDestination);
  };

  const handleDestinationChange = (e) => {
    const inputValue = e.target.value;
    const sentenceCaseInput = inputValue
      .toLowerCase()
      .replace(/\b\w/g, (c) => c.toUpperCase());
    const matchedDestination = destinations.find(
      (dest) => dest.city.toLowerCase() === sentenceCaseInput.toLowerCase()
    );
    setDestination(matchedDestination?.city || sentenceCaseInput);
  };

  const buildQuery = () => {
    let query = `https://www.api.somanymornings.com/api/hotels?city=${destination}&min=${
      min !== undefined ? min : 2
    }&max=${max !== undefined ? max : 99999}`;
    if (roomsmax !== undefined && roomsmax !== "") {
      query += `&roomsmax=${roomsmax}`;
    }
    if (guestsmax !== undefined && guestsmax !== "") {
      query += `&guestsmax=${guestsmax}`;
    }
    if (checkedAmenities.length > 0) {
      query += `&amenities=${checkedAmenities.join(",")}`;
    }
    return query;
  };

  const { data, loading, error, reFetch } = useFetch(buildQuery());

  const handleClick = () => {
    reFetch();
  };

  // Close the dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDestinationDropdown(false); // Close the dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    reFetch();
  }, [destination, min, max, roomsmax, guestsmax, checkedAmenities]);

  const predefinedAmenities = [
    "Free Parking",
    "Free Wi-Fi",
    "Flat Screen TV",
    "Private Kitchen",
    "Power Backup",
    "Air Conditioning",
    "Dedicated Workspace",
    "Heater",
    "Private Parking",
    "Shared Parking",
  ];

  const handleCheckboxChange = (amenity) => {
    setCheckedAmenities((prev) =>
      prev.includes(amenity)
        ? prev.filter((a) => a !== amenity)
        : [...prev, amenity]
    );
  };

  return (
    <div>
      <Navbarinner />
      <section className="hotl-srch">
        <div className="container">
          <div className="row">
            <div className="col-xxl-3 col-xl-3 col-lg-5 col-md-12 col-12">
              <div className="searching-list">
                <h3>Find your getaway</h3>
                <div className="search-area">
                  <div className="lsItem">
                    <label><b>Cottages/Villas/Chalets</b></label>
                    <input
                      type="text"
                      placeholder="Where do you want to go?"
                      className="Destination-top"
                      onClick={handleDestinationClick}
                      onChange={handleDestinationChange}
                      value={destination}
                    />
                    {openDestinationDropdown && (
                      <div className="drpmenu" ref={dropdownRef}>
                        <div className="destinationDropdown main-dd">
                          <h6 className="seares-rec">Recent searches</h6>
                          {destinations.map((dest) => (
                            <div
                              key={dest.city}
                              className="destinationOption"
                              onClick={() => handleDestinationSelect(dest)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-geo-alt"
                                viewBox="0 0 16 16"
                              >
                                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                              </svg>
                              <span className="dest-name">{dest.city}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="lsItem">
                    <div className="lsOptions">
                      <div className="lsOptionItem">
                        <span className="lsOptionText">
                          Min price <small>per night</small>
                        </span>
                        <input
                          type="text"
                          value={min}
                          onChange={(e) => setMin(e.target.value)}
                          className="lsOptionInput"
                        />
                      </div>
                      <div className="lsOptionItem">
                        <span className="lsOptionText">
                          Max price <small>per night</small>
                        </span>
                        <input
                          type="text"
                          onChange={(e) => setMax(e.target.value)}
                          className="lsOptionInput"
                        />
                      </div>
                      <div className="lsOptionItem">
                        <span className="lsOptionText">Bedrooms</span>
                        <input
                          type="number"
                          value={roomsmax}
                          onChange={(e) => setRoomsmax(e.target.value)}
                          className="lsOptionInput"
                        />
                      </div>
                      <div className="lsOptionItem">
                        <span className="lsOptionText">Guests</span>
                        <input
                          type="number"
                          value={guestsmax}
                          onChange={(e) => setGuestsmax(e.target.value)}
                          className="lsOptionInput"
                        />
                      </div>

                      <div className="lsOptionItem1">
                        <span className="lsOptionText1"><b>Amenities</b></span>
                        <ul className="amenitiesul">
                          {predefinedAmenities.map((amenity, index) => (
                            <li className="amenitieslist" key={index}>
                              <span className="amenitieslist">
                                <label className="amenitieslist-label">
                                  <input
                                    type="checkbox"
                                    className="amenitieslist-input"
                                    checked={checkedAmenities.includes(amenity)}
                                    onChange={() =>
                                      handleCheckboxChange(amenity)
                                    }
                                  />
                                  {amenity}
                                </label>
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* <button className="lastserchbtn" onClick={handleClick}>
                    Search
                  </button> */}
                </div>
              </div>
            </div>
            <div className="col-xxl-9 col-xl-9 col-lg-7 col-md-12 col-12">
              <div className="listResult">
                {loading ? (
                  "Loading..."
                ) : (
                  <>
                    {data.length > 0 ? (
                      data.map((item) => (
                        <SearchItem item={item} key={item._id} />
                      ))
                    ) : (
                      <p>No villa found for the selected filters.</p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="holtel-sc">
        <Allstayslider />
      </section> */}
    </div>
  );
};

export default List;
