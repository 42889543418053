import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

const Faqs = () => {
  return (
    <div>
    <section className='allfaqs'>
        <div className='container'>
            <div className='row'>
                <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
                    <div className='faq-outer'>
                        <h2>FAQ,s</h2>
                        <p>Still have questions? We’ve got the answers. </p>
                    </div>
                </div>
                <div className='myfaq'>
                <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header> How do I book a stay with you?  </Accordion.Header>
        <Accordion.Body>
        Booking with us is simple. Just browse through our collection of homes, select desired dates, no guests and click "Book Now." Follow the prompts to complete your reservation. You’ll receive a confirmation email with all your booking details.

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Can I cancel or modify my reservation? </Accordion.Header>
        <Accordion.Body>
        We understand plans can change. Each property has its own cancellation policy, which you can find during the booking process. Furthermore, you can please check our cancellation policy for more details. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Do I need to bring my own linens and towels?</Accordion.Header>
        <Accordion.Body>
        No need to pack extra linens. Our properties are fully equipped with clean, fresh bed linens, towels, and toiletries to make your stay as comfortable as possible.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Is Wi-Fi available at all properties? </Accordion.Header>
        <Accordion.Body>
        Yes, all our properties offer high-speed Wi-Fi to keep you connected during your stay, whether for work, entertainment, or simply staying in touch with loved ones.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>Can I host an event or party at the property?  </Accordion.Header>
        <Accordion.Body>
        We love hosting memorable gatherings, but some properties have specific guidelines about parties or large events. Please get in touch with us beforehand if you're planning a special occasion so we can ensure the property is the perfect fit for your needs.

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header> Are there any special discounts or promotions available?</Accordion.Header>
        <Accordion.Body>
        Yes. Keep an eye on our website to stay ahead of seasonal discounts, early bird specials, and exclusive deals. Also, follow us on social media for the latest offers. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header> Can I bring my pet along?</Accordion.Header>
        <Accordion.Body>
        Some of our properties are pet-friendly. If you’re traveling with a furry friend, check the property details to see if pets are allowed. Additional charges may apply, and we ask that pets be kept on a leash for everyone’s safety and enjoyment. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7">
        <Accordion.Header>Can I bring extra guests or visitors? </Accordion.Header>
        <Accordion.Body>
        Each property has its own occupancy limit specified in the property details. If you plan on inviting additional guests or visitors, please check the property details or contact us to ensure it’s possible without violating house rules.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </div>
            </div>
        </div>
    </section>

 </div>

  );
};

export default Faqs;
