import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Contactpgesections from "../../components/Homestay-components/Contactpgesections";
import Navbar4 from "../../components/navbar/Navbar4";


const Contact = () => {

  const backgroundImg = "/assets/img//bg/Contact.webp";
  const pageTitle = "We’d Love To Hear From You";
  const pageSubheading = "We’d Love To Hear From You";

  return (
    <div>
      <div className="main-header-2">
    <Navbar4 />
  </div>
   <Breadcrumb
      backgroundImg={backgroundImg}
      title={pageTitle}
      subheading={pageSubheading}
    />

     {/* <Contactpagebnr /> */}
      <Contactpgesections />
  
    </div>
  );
};

export default Contact;
