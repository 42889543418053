
import React from "react";

import "./hotel.css";

import { DateRange } from "react-date-range";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import photoicon from "../../img/photoicon.png";
import right from "../../img/check-list.png";
import videoicon from "../../img/videoicon.png";
import { MdOutlineStar } from "react-icons/md";
import { SlCalender } from "react-icons/sl";
import { BiUser } from "react-icons/bi";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleXmark,
  faLocationDot,
  faCalendarDays,
  faPerson,
} from "@fortawesome/free-solid-svg-icons";
import { useContext, useState, useEffect, useRef } from "react";
import useFetch from "../../hooks/useFetch";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import Reserve from "../../components/reserve/Reserve";
import Hotelslider from "../../components/Slider/hotelslider.js";
import lmap from "../../img/newimg/lmap.png";
import "./hotelsection.css";
import SecondSlider from "../../components/searchlist/secondslider.jsx";
import Updatedpropertyslider from "../../components/my-components/Updated-property-slider.js";
import Allstayslider from "../../components/my-components/Allstayslider.js";
import Navbarinner from "../../components/navbar/Navbarinner.jsx";
import Payment from "../payment.js";


const Hotel = () => {

  // RAZORPAY STARTED
  // const handlePayment = async () => {
  //   const options = {
  //     key: "rzp_test_J9wxk5gscTXPA3", // Replace with your Razorpay Key ID
  //     amount: 5000, // Amount in paise (50000 paise = ₹500)
  //     currency: "INR",
  //     name: "So Many Mornings",
  //     description: "Test Transaction",
  //     image: "https://yourlogo.com/logo.png", // Optional
  //     handler: function (response) {
  //       alert(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);
  //       console.log(response);
  //     },
  //     prefill: {
  //       name: "John Doe",
  //       email: "johndoe@example.com",
  //       contact: "9999999999",
  //     },
  //     notes: {
  //       address: "Razorpay Corporate Office",
  //     },
  //     theme: {
  //       color: "#3399cc",
  //     },
  //   };

  //   const razorpay = new window.Razorpay(options);
  //   razorpay.open();
  // };
  // RAZORPAY ENDED

  // return (
  //   <button onClick={handlePayment} style={{ padding: "10px 20px", fontSize: "16px" }}>
  //     Book Now
  //   </button>
  // );
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [activeTab, setActiveTab] = useState(1);
  const backgroundImg = "/assets/img//bg/inrbnr.webp";
  const pageTitle = "Hotel booking search";
  const pageSubheading = "Discover the exciting adventures that await you.";
  const [openDate, setOpenDate] = useState(false);
  const [openAmenitiesModal, setOpenAmenitiesModal] = useState(false);
  const location = useLocation();
  const hotelNameEncoded = location.pathname.split("/")[2];
  const hotelName = decodeURIComponent(hotelNameEncoded);
  console.log(hotelName);
  const [slideNumber, setSlideNumber] = useState(0);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const handleVideoOpen = () => {
    setOpenVideoModal(true);
  };
  const handleAmenitiesOpen = () => {
    setOpenAmenitiesModal(true);
  };

  const handleAmenitiesClose = () => {
    setOpenAmenitiesModal(false);
  };
  const handleVideoClose = () => {
    setOpenVideoModal(false);
  };
  const { data, loading, error } = useFetch(
    `https://www.api.somanymornings.com/api/hotels/findByName/${hotelName}`
  );
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const dataArray = Array.isArray(data) ? data : [data];
  // const { dates, options } = useContext(SearchContext);
  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(), // Set end date to next day
      key: "selection",
    },
  ]);
  const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;
  function dayDifference(date1, date2) {
    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    const diffDays = Math.ceil(timeDiff / MILLISECONDS_PER_DAY);
    return diffDays;
  }
  const [openOptions, setOpenOptions] = useState(false);
  const [options, setOptions] = useState({
    adult: 1,
    children: 0,
    room: 1,
  });
  const generalAmenitiesCount = data.amenities?.length || 0;
  const kitchenAmenitiesCount = data.kitchenamenities?.length || 0;
  const roomAmenitiesCount = data.roomamenities?.length || 0;
  const bathroomAmenitiesCount = data.bathroomamenities?.length || 0;
  const commonAmenitiesCount = data.commonamenities?.length || 0;

  const totalAmenitiesCount =
    generalAmenitiesCount +
    kitchenAmenitiesCount +
    roomAmenitiesCount +
    bathroomAmenitiesCount +
    commonAmenitiesCount;
  const handleOption = (name, operation) => {
    setOptions((prev) => {
      let maxAdults;
      switch (data.name) {
        case "The-Silverton-Chalet":
          maxAdults = 8;
          break;
        case "The-Mini-Horizon":
          maxAdults = 2;
          break;
        case "The-Horizon-Villa":
          maxAdults = 8;
          break;
        case "The-Eleven-Oaks":
          maxAdults = 4;
          break;
        case "The-Log-House":
          maxAdults = 4;
          break;
        case "The-Pink-Magnolia-Cottage":
          maxAdults = 8;
          break;
        case "The-SI-Cottage":
          maxAdults = 6;
          break;
        case "The-Garden-Cottage":
          maxAdults = 8;
          break;
          case "The-Silver-Doon":
            maxAdults = 8;
            break;
        default:
          maxAdults = Infinity;
      }

      return {
        ...prev,
        [name]:
          operation === "i"
            ? Math.min(prev[name] + 1, maxAdults)
            : Math.max(prev[name] - 1, 0),
      };
    });
  };
  const days =
    dates && dates[0] && dates[0].endDate && dates[0].startDate
      ? dayDifference(dates[0].endDate, dates[0].startDate)
      : 1;

  const handleOpen = (initialSlideNumber = 0) => {
    setSlideNumber(initialSlideNumber);
    setOpen(true);
  };
  const [endDateSelected, setEndDateSelected] = useState(false);
  const handleDateChange = (item) => {
    const { startDate, endDate } = item.selection;

    // If start date is selected, but end date is not, update start date only
    if (!endDateSelected) {
      setDates([
        {
          startDate,
          endDate,
          key: "selection",
        },
      ]);
    } else {
      // Both start and end dates are selected, update both
      setDates([
        {
          startDate,
          endDate,
          key: "selection",
        },
      ]);
      setOpenDate(false); // Close the date picker after selecting both dates
    }

    // Toggle the end date selected state
    setEndDateSelected(!endDateSelected);
  };
  const handleMove = (direction) => {
    const filteredPhotos = data.photos.slice(7); // Exclude the first 7 photos
    const totalImages = filteredPhotos.length; // Adjusted total count
    let newSlideNumber;

    if (direction === "l") {
      newSlideNumber = slideNumber === 0 ? totalImages - 1 : slideNumber - 1;
    } else {
      newSlideNumber = slideNumber === totalImages - 1 ? 0 : slideNumber + 1;
    }

    setSlideNumber(newSlideNumber);
  };

  const handleClick = () => {
    if (user) {
      if (days > 0) {
        setOpenModal(true);
      } else {
        alert("Please select valid check-in and check-out dates.");
      }
    } else {
      navigate("/login");
    }
  };

  const datePickerRef = useRef();
  const optionsRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setOpenDate(false);
      }
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setOpenOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [datePickerRef]);
  return (
    <div>
      <Navbarinner />
      <section className="blockimg">
        <div className="container">
          <div className="row" id="newlyrow">
            <div
              className="col-xxl-8 col-xl-8 col-lg-7 col-md-12 col-12"
              id="single-property-row"
            ></div>
            <div className="hotelImages">
              <div className="firstImageRow">
                {data.photos?.slice(0, 1).map((photo, i) => (
                  <div className="hotelImgWrapper1" key={i}>
                    <img
                      onClick={() => handleOpen(i)}
                      src={photo}
                      alt=""
                      className="hotelImg"
                    />
                  </div>
                ))}
              </div>

              <div className="otherImagesRow">
                {data.photos?.slice(1, 3).map((photo, i) => (
                  <div className="hotelImgWrapper" key={i + 1}>
                    <img
                      onClick={() => handleOpen(i + 1)}
                      src={photo}
                      alt=""
                      className="hotelImg"
                    />
                  </div>
                ))}
              </div>
              <div className="otherImagesRows">
                {data.photos?.slice(3, 5).map((photo, i) => (
                  <div className="hotelImgWrapper" key={i + 1}>
                    <img
                      onClick={() => handleOpen(i + 1)}
                      src={photo}
                      alt=""
                      className="hotelImg"
                    />
                  </div>
                ))}
              </div>
              <div className="otherImagesRow3s">
                {data.photos?.slice(5, 7).map((photo, i) => (
                  <div className="hotelImgWrapper" key={i + 1}>
                    <img
                      onClick={() => handleOpen(i + 1)}
                      src={photo}
                      alt=""
                      className="hotelImg"
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="view-prop">
              <ul>
                <li>
                  <Link
                    onClick={() => handleOpen(0)}
                    className="hotelImagesdata"
                  >
                    {" "}
                    <span>
                      <img
                        src={photoicon}
                        className="photoicon"
                        alt="photoicon"
                      />
                    </span>
                    View Photos
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={handleVideoOpen}
                    className="hotelImagesdata"
                    id="videoicon00"
                  >
                    <span>
                      <img
                        src={videoicon}
                        className="photoicon"
                        alt="photoicon"
                      />
                    </span>
                    Watch Video
                  </Link>
                </li>
              </ul>

              <h6 className="hotelImagesdata"></h6>
              <h6 className="hotelImagesdata"></h6>
            </div>
          </div>
        </div>
      </section>
      <div className="tester"></div>
      <section className="single-property">
        <div className="container">
          <div className="row " id="hoteldetailrow">
            <div
              className="col-xxl-8 col-xl-7 col-lg-7 col-md-12 col-12"
              id="single-property-row"
            >
              <div className="single-property-detail">
                {open && (
                  <div className="slider">
                    <FontAwesomeIcon
                      icon={faCircleXmark}
                      className="close"
                      onClick={() => setOpen(false)}
                    />
                    <FontAwesomeIcon
                      icon={faCircleArrowLeft}
                      className="arrow"
                      onClick={() => handleMove("l")}
                    />
                    <div className="sliderWrapper">
                      <img
                        src={data.photos.slice(7)[slideNumber]} // Filter photos to skip first 7
                        alt=""
                        className="sliderImg"
                      />
                    </div>
                    <FontAwesomeIcon
                      icon={faCircleArrowRight}
                      className="arrow"
                      onClick={() => handleMove("r")}
                    />
                  </div>
                )}
                {openVideoModal && (
                  <div className="slider videoModal">
                    <FontAwesomeIcon
                      icon={faCircleXmark}
                      className="close"
                      onClick={handleVideoClose}
                    />
                    <div className="sliderWrapper">
                      {data.video ? (
                        <video className="property-dec" autoPlay controls muted>
                          <source src={data.video} type="video/mp4" />
                        </video>
                      ) : (
                        <p>Loading video...</p>
                      )}
                    </div>
                  </div>
                )}
                {openAmenitiesModal && (
                  <div className="slider amenitiesModal">
                    <FontAwesomeIcon
                      icon={faCircleXmark}
                      className="close"
                      onClick={handleAmenitiesClose}
                    />
                    {/* <div className="row">
                      <div className="col-12">
                        <h3 className="amenityName">All Amenities</h3>
                      </div>
                    </div> */}

                    <div className="sliderWrapper">
                      <div className="single-property-pgs">
                        <Tabs>
                          <TabList>
                            <Tab> General Amenities</Tab>
                            <Tab>Kitchen Amenities</Tab>
                            <Tab>Room Amenities</Tab>
                            <Tab>Bathroom Amenities</Tab>
                            <Tab>Common Amenities</Tab>
                          </TabList>

                          <TabPanel>
                            <div className="Deiption">
                              <h4 className="amenityName">General Amenities</h4>
                              {data.amenities?.slice(6, 20).map((amenity, index) => (
                                <div className="amenityItem" key={index}>
                                  <img src={right} alt="" className="amenityimg" />
                                  <span className="amenityName">{amenity}</span>
                                </div>
                              ))}
                            </div>
                          </TabPanel>

                          <TabPanel>
                            <div className="Deiption">
                              <h4 className="amenityName">Kitchen Amenities</h4>
                              {data.kitchenamenities?.map((kitchen, index) => (
                                <div className="amenityItem" key={index}>
                                  <img
                                    src={right}
                                    alt=""
                                    className="amenityimg"
                                  />
                                  <span className="amenityName">{kitchen}</span>
                                </div>
                              ))}
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="Deiption">
                              <div className="properesdiv">
                                <h4 className="amenityName">Room Amenities</h4>
                                {data.roomamenities?.map((room, index) => (
                                  <div className="amenityItem" key={index}>
                                    <img
                                      src={right}
                                      alt=""
                                      className="amenityimg"
                                    />
                                    <span className="amenityName">{room}</span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="Deiption">
                              <div className="properesdiv">
                                <h4 className="amenityName">
                                  Bathroom Amenities
                                </h4>
                                {data.bathroomamenities?.map((bath, index) => (
                                  <div className="amenityItem" key={index}>
                                    <img
                                      src={right}
                                      alt=""
                                      className="amenityimg"
                                    />
                                    <span className="amenityName">{bath}</span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="Deiption">
                              <div className="properesdiv">
                                <h4 className="amenityName">
                                  Common Amenities
                                </h4>
                                {data.commonamenities?.map((common, index) => (
                                  <div className="amenityItem" key={index}>
                                    <img
                                      src={right}
                                      alt=""
                                      className="amenityimg"
                                    />
                                    <span className="amenityName">
                                      {common}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </TabPanel>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                )}
                <div className="hotelWrapper-2">
                  <div className="propty-heading">
                    {(() => {
                      // Retrieve user information from local storage
                      const user =
                        JSON.parse(localStorage.getItem("user")) || null;

                      // Check if user is logged in safely
                      const isLoggedIn = user || user !== null;

                      // Render content based on login status
                      if (isLoggedIn) {
                        // Safely access `cheapestPrice` with a default value of 0
                        const originalPrice = data?.cheapestPrice || 0;

                        // Calculate discounted price
                        const discountedPrice =
                          originalPrice - originalPrice * 0.1;

                        return (
                          <h2 className="single-heading">
                            {data.name ? data.name.replace(/-/g, " ") : ""}
                            <span className="single-price">
                              ₹{discountedPrice.toLocaleString("en-IN")}
                            </span>
                          </h2>
                        );
                      } else {
                        return (
                          <h2 className="single-heading">
                            {data.name ? data.name.replace(/-/g, " ") : ""}
                            <span className="single-price">
                              ₹
                              {(data.cheapestPrice || 0).toLocaleString(
                                "en-IN"
                              )}
                            </span>
                          </h2>
                        );
                      }
                    })()}
                  </div>

                  <div className="property-location new121">
                    <FontAwesomeIcon icon={faLocationDot} />
                    <span>{data.address}</span>
                  </div>
                  <ul className="inner-prop-icon">
                    {data.photos2?.slice(0, 10).map((photo, i) => (
                      <li className="hotelImgWrapper1" key={i}>
                        <img src={photo} alt="" className="hotelImg" />
                      </li>
                    ))}
                  </ul>

                  <div className="properties-facilities">
                    <div className="single-property-pg">
                      <Tabs>
                        <TabList>
                          <Tab> Overview</Tab>
                          <Tab>House Rules</Tab>
                          <Tab>What’s Nearby</Tab>
                        </TabList>

                        <TabPanel>
                          <div className="Deiption">
                            <h3>Overview</h3>
                            <p className="property-dec">{data.desc}</p>
                          </div>
                        </TabPanel>
                        <TabPanel>
                          <div className="Deiption">
                            <h3>Stay Guidelines</h3>
                            <ul className="entry-detail list-rules unorderlistrow">
                              {data.rules && data.rules.length > 0 ? (
                                data.rules.map((rule, index) => (
                                  <li key={index} className="amenity-item1">
                                    {rule}
                                  </li>
                                ))
                              ) : (
                                <p>No rules available.</p>
                              )}
                            </ul>
                          </div>
                        </TabPanel>
                        <TabPanel>
                          <div className="Deiption">
                            <div className="properesdiv">
                              <h3>Nearby Locations</h3>
                              <ul className="entry-detail list-rules nearbylist">
                                {data.nearby && data.nearby.length > 0 ? (
                                  data.nearby.map((near, index) => (
                                    <li key={index} className="amenity-item1">
                                      {near}
                                    </li>
                                  ))
                                ) : (
                                  <p>No rules available.</p>
                                )}
                              </ul>
                            </div>
                          </div>
                        </TabPanel>
                      </Tabs>
                    </div>
                  </div>
                  <div className="propertiess">
                    <h3>Amenities</h3>
                    <div className="row" id="new-amenities">
                      {dataArray.map((item, index) => {
                        const photos = item.photos1?.slice(0, 6) || []; // Get first 4 photos
                        const amenities = item.amenities?.slice(0, 6) || []; // Get first 4 amenities
                        const maxLength = Math.max(
                          photos.length,
                          amenities.length
                        ); // Ensure loop runs correctly

                        return Array.from({ length: maxLength }).map((_, i) => (
                          <div
                            key={`${index}-combined-${i}`}
                            className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-12"
                            id="amenities-01"
                          >
                            <div className="new-amenity-box">
                              <div className="cs-info-box-img">
                                <img
                                  src={photos[i] || "/default-photo.jpg"}
                                  className="parking01"
                                  alt={`Photo ${i + 1}`}
                                />
                              </div>
                              <div className="cs-info-box-content">
                                <h6 className="cs-title cs-info-box-title">
                                  {amenities[i] || "Default Amenity"}
                                </h6>
                              </div>
                            </div>
                          </div>
                        ));
                      })}
                      <div
                        className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-12"
                        id="amenities-01"
                      >
                      <div className="forammaenites">
                        <Link onClick={handleAmenitiesOpen} className="morenk" id="">
                          +{totalAmenitiesCount - 6} More Amenities
                        </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="properties-facilities">
                    <h3>
                      <strong>Home Truths</strong>
                    </h3>
                    <div className="additionalfacilities" id="">
                      <div
                        className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 "
                        id="aditionalfacilites-01"
                      >
                        <div className="additionalfacilitydiv2">
                          {/* <div className="additionalfacilitydiv3">
                            <img
                              src={fire0}
                              className="additionalfacilitydiv4"
                              alt=""
                            />
                          </div> */}
                          <div className="additionalfacilitydiv5">
                            <h4 className="additionalfacilitydiv6">
                              <strong>
                                {data.facilities && data.facilities.length > 0
                                  ? data.facilities[0]
                                  : "No facilities available"}
                              </strong>
                            </h4>
                            <p>
                              {data.facilities && data.facilities.length > 0
                                ? data.facilities[1]
                                : "No facilities available"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12"
                        id="aditionalfacilites-01"
                      >
                        <div className="additionalfacilitydiv2">
                          {/* <div className="additionalfacilitydiv10">
                            <img
                              src={chef}
                              className="additionalfacilitydiv41"
                              alt=""
                            />
                          </div> */}
                          <div className="additionalfacilitydiv5">
                            <h6 className="additionalfacilitydiv6">
                              <strong>
                                {data.facilities && data.facilities.length > 0
                                  ? data.facilities[2]
                                  : "No facilities available"}
                              </strong>
                            </h6>
                            <p>
                              {data.facilities && data.facilities.length > 0
                                ? data.facilities[3]
                                : "No facilities available"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-12 col-12">
              <div className="hotelDetailsPrice">
                <h3 className="Perfect-price">
                  <span>
                    ₹
                    {(() => {
                      // Retrieve user information from local storage
                      const user =
                        JSON.parse(localStorage.getItem("user")) || null;

                      // Check if user is logged in safely
                      const isLoggedIn = user || user !== null;

                      // Render content based on login status
                      if (isLoggedIn) {
                        // Safely access `cheapestPrice` with a default value of 0
                        const originalPrice = data?.cheapestPrice || 0;

                        // Calculate discounted price
                        const discountedPrice =
                          originalPrice - originalPrice * 0.1;

                        return (
                          <span className="single-price12">
                            {discountedPrice.toLocaleString("en-IN")}/night
                          </span>
                        );
                      } else {
                        return (
                          <span className="single-price2">
                            {(data.cheapestPrice || 0).toLocaleString("en-IN")}
                            /night
                          </span>
                        );
                      }
                    })()}
                  </span>
                </h3>
                {/* <h3 className="Perfect-price">Perfect for a {days}-night stay!</h3> */}
                <p>
                  Located in <span className="cityupper">{data.city}</span>,
                  this delightful property has a rating of{" "}
                  {Array.from({ length: Math.floor(data.rating) }).map(
                    (_, index) => (
                      <MdOutlineStar key={index} />
                    )
                  )}
                </p>
                {/* <h4 className="single-heading"><span >Full Property ₹ {data.cheapestPrice}/night </span></h4> */}
                <div className="out-signle-form">
                  <div className="rightform">
                    <p className="gts">Check In - Check Out</p>
                    <div className="headerSearchItem7">
                      <SlCalender />
                      <span
                        className="dnput"
                        onClick={() => setOpenDate(!openDate)}
                      >
                        {dates &&
                          dates[0] &&
                          format(dates[0].startDate, "MM/dd/yyyy")}{" "}
                        -{" "}
                        {dates &&
                          dates[0] &&
                          format(dates[0].endDate, "MM/dd/yyyy")}
                      </span>

                      {openDate && (
                        <div className="out-dater" ref={datePickerRef}>
                          <DateRange
                            editableDateInputs={true}
                            onChange={handleDateChange}
                            moveRangeOnFirstSelection={false}
                            ranges={dates}
                            className="check-date"
                            minDate={new Date()}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="right-two">
                    <p className="gts">Guests</p>
                    <div className="hok">
                      <BiUser />

                      <span
                        onClick={() => setOpenOptions(!openOptions)}
                        className="headerSearchText"
                      >{`${options.adult} adult · ${options.children} children `}</span>
                    </div>

                    <div className="AdultSearch">
                      {openOptions && (
                        <div className="options" ref={optionsRef}>
                          <div className="optionlist">
                            <p className="optionlist">Adult</p>
                            <div className="optionplus">
                              <button
                                disabled={options.adult <= 1}
                                className="optionplusButton"
                                onClick={() => handleOption("adult", "d")}
                              >
                                -
                              </button>
                              <span className="optionCounterNumber">
                                {options.adult}
                              </span>
                              <button
                                className="optionplusButton"
                                onClick={() => handleOption("adult", "i")}
                              >
                                +
                              </button>
                            </div>
                          </div>
                          <div className="optionlist">
                            <div className="option">
                              <p className="optionlist">Children</p>
                              <div className="optionplus">
                                <button
                                  disabled={options.children <= 0}
                                  className="optionplusButton"
                                  onClick={() => handleOption("children", "d")}
                                >
                                  -
                                </button>
                                <span className="optionCounterNumber">
                                  {options.children}
                                </span>
                                <button
                                  className="optionplusButton"
                                  onClick={() => handleOption("children", "i")}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="Proding2">
                    {/* <button onClick={handleClick} className="explore-button1">
                      Book Now <span className="arrow1">→</span>
                    </button> */}
{/* 
                    <button onClick={handlePayment} className="explore-button1">
                      Book Now <span className="arrow1">→</span>
                    </button> */}

                    <Payment />

                  </div>
                  {/* <button onClick={handleClick} disabled={days === 0}>Reserve or Book Now!</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="holtel-sc">
     <Allstayslider />
      </section>
      {openModal && (
        <Reserve
          setOpen={setOpenModal}
          hotelId={data._id}
          dates={dates}
          options={options}
          cheapestPrice={(data.cheapestPrice || 0).toLocaleString("en-IN")}
          maxPeople={data.maxPeople}
        />
      )}
    </div>
  );
  
};

export default Hotel;
