import React from "react";
import { useEffect } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import area from "../../../src/img/area.png";
import bedroom from "../../../src/img/bedroom.png";
import bath from "../../../src/img/bath.png";
import cutlery from "../../../src/img/cutlery.png";
import wifirouter from "../../../src/img/wifirouter.png";
import heater from "../../../src/img/heater.png";
import fireplace from "../../../src/img/fireplace.png";
import bbq from "../../../src/img/bbq.png";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./searchItem.css";

const SearchItem = ({ item }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const truncateText = (text, maxLength) => {
    const words = text.split(' ');
    if (words.length > maxLength) {
      return words.slice(0, maxLength).join(' ') + '...';
    }
    return text;
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:true,
  };

  return (
    <section className="right-serch-list">
      <div className="container">
        <div className="row" id="proprow">
          <div className="col-xxl-4 col-xl-5 col-lg-12 col-md-5 col-12" id="smallpick">
            <Slider {...settings}>
              {item.photos.slice(0, 7).map((photo, index) => (
                <div className="slider-photos" key={index}>
                  <img src={photo} alt={`Property Image ${index + 1}`} className="propimg" />
                </div>
              ))}
            </Slider>
          </div>
          <div className="col-xxl-6 col-xl-5 col-lg-12 col-md-7 col-12 just" id="forborder">
            <div className="siD">
              <h3 className="Title">{item.name ? item.name.replace(/-/g, " ") : ""}</h3>
              <p className="Distane">{item.distance}</p>
              <ul className="inner-prop-icon">
                {item.photos2?.slice(0, 10).map((photo, i) => (
                  <li className="hotelImgWrapper1" key={i}>
                    <img src={photo} alt="" className="hotelimgsmall" />
                  </li>
                ))}
              </ul>
              <p className="Located">{truncateText(item.desc, 20)}</p>
            </div>
          </div>
          <div className="col-xxl-2 col-xl-2 col-lg-12 col-md-12 col-12 just">
            <div className="siDetails">
              {/* {item.rating && <div className="siRating">
                <span>Excellent</span>
                <button>{item.rating}</button>
              </div>} */}
              <div className="siDetailTexts">
                <span className="siPrice">₹ {(item.cheapestPrice || 0).toLocaleString(
                                "en-IN"
                              )}<span className="niht">/night onwards</span></span>
                <Link to={`/stays/${encodeURIComponent(item.name.toLowerCase())}`}>
                  <button className="siCheckButton">Book Now</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SearchItem;
