import React from "react";
import gallerypick1 from "../../img/newimg/gallerypick1.png";
import gallerypick4 from "../../img/newimg/gallerypick4.png";
import gallerypick7 from "../../img/newimg/gallerypick7.png";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const Doubleslidermobile = () => {
    return (
        <section className="Doubleslidermobile">
            <div className="container">
                <div className="row text-center" id="nestled">
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                        <div className="box">
                        <h2> Stays for Every Traveler</h2>
                        <p className="">Looking to escape to a cozy cottage, a luxury villa with scenic views, or perhaps a charming chalet tucked away in nature? Take your pick from our expertly vetted collection of the most incredible homes.</p>
                        </div>
                    </div>
                </div>
                <div className="dbs">
                    <Swiper
                        spaceBetween={30}
                        slidesPerView={3}
                        autoplay={{
                            delay: 2000,
                            disableOnInteraction: true,
                        }}
                        loop={true}
                        navigation={{
                            nextEl: ".custom-next",
                            prevEl: ".custom-prev",
                        }}
                        modules={[Autoplay, Navigation]}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                            },
                            640: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 2,
                            },

                        }}
                    >

                        <div className="mobile-slider">

                            <SwiperSlide>
                                <div className="gallerypick1">
                                    <img src={gallerypick1} alt="ddlider" className="gallerypick1" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="gallerypick1">
                                    <img src={gallerypick4} alt="ddlider" className="gallerypick1" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="gallerypick1">
                                    <img src={gallerypick7} alt="ddlider" className="gallerypick1" />
                                </div>
                            </SwiperSlide>

                        </div>
                    </Swiper>
                </div>
                <div className="row">
                    <div className="Proding2">
                        <a href="#booking1" className="explore-button1">
                            Book Now <span className="arrow1">→</span>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Doubleslidermobile;
