import React from "react";
import Slider from "react-slick";
import star from "../../img/5star.png";
import bbnnb from "../../img/newimg/bbnnb.png";

function Testimonials() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="testimonials text-center">
      <div className="container">
        <Slider {...settings}>
          <div className="slickslide">
            <div className="rewies">
              <img src={bbnnb} className="bbnnb" alt="" />
              <p className="review-light">Sanidya </p>
              <img src={star} className="reviewimg" alt="" />

              <p className="main-review">
                Place as clean and beautiful as mentioned in the photos. The
                staff was excellent, and the hospitality and food were amazing.
                Every corner was beautifully made and decorated. We really loved
                the bonfire and the food.
              </p>
            </div>
          </div>
          <div className="slickslide">
            <div className="rewies">
              <img src={bbnnb} className="bbnnb" alt="" />
              <p className="review-light">Sunny</p>
              <img src={star} className="reviewimg" alt="" />
              {/* <h5 className="review-name">Log House </h5> */}
              <p className="main-review">
                Excellent hospitality and support from Mrs Kaur and the property
                caretaker. Would like to recommend others to visit as well.
                Thanks
              </p>
            </div>
          </div>

          <div className="slickslide">
            <div className="rewies">
              <img src={bbnnb} className="bbnnb" alt="" />
              <p className="review-light">Ankita</p>
              <img src={star} className="reviewimg" alt="" />
              {/* <h5 className="review-name">The Horizon villa </h5> */}
              <p className="main-review">
                Great villa, both location and amenities. The caretaker and chef
                team accommodated all our needs. Special mention to Chef Dinesh,
                who made our meals a joy to experience. Highly recommend it to
                anyone needing some peace and quiet while living right on Mall
                Road.
              </p>
            </div>
          </div>
          <div className="slickslide">
            <div className="rewies">
              <img src={bbnnb} className="bbnnb" alt="" />
              <p className="review-light">Sanidya </p>
              <img src={star} className="reviewimg" alt="" />
              {/* <h5 className="review-name">Silverton </h5> */}
              <p className="main-review">
                Place as clean and beautiful as mentioned in the photos. The
                staff was excellent, and the hospitality and food were amazing.
                Every corner was beautifully made and decorated. We really loved
                the bonfire and the food.
              </p>
            </div>
          </div>
          <div className="slickslide">
            <div className="rewies">
              <img src={bbnnb} className="bbnnb" alt="" />
              <p className="review-light">Vandan Ved</p>
              <img src={star} className="reviewimg" alt="" />
              {/* <h5 className="review-name">Silverton </h5> */}
              <p className="main-review">
                The apartment was super clean and felt very safe to stay in
                corona times. The owner was super helpful and provided a
                domestic help at all times who would do things like cooking and
                cleaning which was absolutely amazing.
              </p>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
}

export default Testimonials;
