import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Footer1 from "../components/footer/Footer";
import Homeheader from "../components/navbar/Homeheader";
import TransparentNavbar from "../components/navbar/Navbar";
import Navbar from "../components/navbar/Navbar";
import Navbar2 from "../components/navbar/Navbar2";
import Footer2 from "../components/footer/Footer2";
import Newheader from "../components/navbar/Newheader";
import Navbar4 from "../components/navbar/Navbar4";

const Cancellationpolicy = () => {
  const backgroundImg = "/assets/img//bg/inrbnr.webp";
  const pageTitle = "Cancellation Policy ";
  // const pageSubheading =
  //   " Discover what personal information we collect and how we use it. ";

  return (
    <div>
   <div className="main-header-2">
    <Navbar4 />
  </div>
      {/* <TransparentNavbar /> */}
      {/* <Homeheader /> */}
      <Breadcrumb
        backgroundImg={backgroundImg}
        title={pageTitle}
        // subheading={pageSubheading}
      />
      <section className="privacypolicy">
        <div className="container">
          <div className="row text-center">
            <h2>Cancellation Policy</h2>
            <div className="out-box-content">
              <p>
              Guests can cancel their reservation at any time. However, cancellations made within 7 days of the  <br className="d-none d-lg-block" />check-in date are not eligible for a refund. We recommend carefully reviewing your plans  <br className="d-none d-lg-block" />before booking to avoid any inconvenience. 
              </p>
            
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Cancellationpolicy;
