import React from "react";
import ns4 from "../../img/ns4.webp";
import ns3 from "../../img/ns3.webp";
import ns2 from "../../img/ns2.webp";
import ns1 from "../../img/ns1.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const Updatedpropertyslider = () => {
  return (
    <section className="hp-room-section">
      <div className="hp-room-items">
        <div className="container">
          <div className="Industriesserve-wrapper ">
            <div className="Industriesserve-slider">
              <Swiper
                spaceBetween={30}
                slidesPerView={3}
                // autoplay={{
                //     delay: 3000,
                //     disableOnInteraction: true,
                // }}
                loop={true}
                navigation={{
                  nextEl: ".custom-next",
                  prevEl: ".custom-prev",
                }}
                // modules={[Autoplay, Navigation]}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                  },
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                }}
              >
                <SwiperSlide>
                  <div
                    className="hp-room-item set-bg"
                    style={{
                      backgroundImage: "url(" + ns1 + ")",
                    }}
                  >
                    <div className="gred"></div>
                    <div className="hr-text">
                      <h3>The SI Cottage</h3>
                      <p className="Uttrakhand-li">
                        Mussoorie, Uttrakhand, India
                      </p>
                      <h2>
                        ₹ 28000<span>/ Per night</span>
                      </h2>

                      <a href="/hotels/the-si-cottage" className="primary-btn">
                        More Details
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="hp-room-item set-bg"
                    style={{
                      backgroundImage: "url(" + ns2 + ")",
                    }}
                  >
                    <div className="gred"></div>
                    <div className="hr-text">
                      <h3>The Horizon Villa</h3>
                      <p className="Uttrakhand-li">
                        Mussoorie, Uttrakhand, India
                      </p>
                      <h2>
                        ₹ 28000<span>/ Per night</span>
                      </h2>

                      <a
                        href="/hotels/the-horizon-villa"
                        className="primary-btn"
                      >
                        More Details
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="hp-room-item set-bg"
                    style={{
                      backgroundImage: "url(" + ns4 + ")",
                    }}
                  >
                    <div className="gred"></div>
                    <div className="hr-text">
                      <h3>The Silverton Chalet</h3>
                      <p className="Uttrakhand-li">
                        Mussoorie, Uttrakhand, India
                      </p>
                      <h2>
                        ₹ 18000<span>/ Per night</span>
                      </h2>

                      <a
                        href="/hotels/the-silverton-chalet"
                        className="primary-btn"
                      >
                        More Details
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="hp-room-item set-bg"
                    style={{
                      backgroundImage: "url(" + ns3 + ")",
                    }}
                  >
                    <div className="gred"></div>
                    <div className="hr-text">
                      <h3>The Log House</h3>
                      <p className="Uttrakhand-li">
                        Mussoorie, Uttrakhand, India
                      </p>
                      <h2>
                        ₹ 7500<span>/ Per night</span>
                      </h2>

                      <a href="/hotels/the-log-house" className="primary-btn">
                        More Details
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Updatedpropertyslider;
