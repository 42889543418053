import React from "react";
import ns3 from "../../img/ns3.webp";
import horizonvilla from "../../img/newimg/layer123.png";
import TheSilvertonChalet from "../../img/TheSilvertonChalet.webp";
import { Link } from "react-router-dom";
import sicottage from "../../img/sicottage.webp";
import Pinkmangolia from "../../img/Pinkmangolia.webp";
import GardenCottage from "../../img/GardenCottage.webp";
import minihorizon from "../../img/minihorizon.webp";


const Stayssections = () => {
  return (
    <div>
      <section className="Stayssections">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="homely">
                <h2>Our Luxury Retreats</h2>
                <p className="tranquility">
                Our exquisite homes have everything you need, and nothing you don’t.
                </p>
              </div>
            </div>
          </div>
          <div className="row" id="propty">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
    
              <div className="propertybox1 ">
             
                <img src={ns3} className="HSPick1" alt="" />
                <div className="propertybox1-content">
                  <Link to={`/stays/the-log-house`}>
                    <h2 className="text-white">The Log House</h2>
                  </Link>
                  <ul>
                    <li>2 Bedrooms  </li>
                    <li>Modernized Kitchen </li>
                    <li>Living Room </li>
                    <li>Wi-Fi/TV  </li>
                  </ul>
                  <ul>
                    <li>Pet-Friendly  </li>
                    <li>Doon Valley Views  </li>
                    <li>Free Parking  </li>
                  </ul>
                  <div className="Proding2">
                  <a href="/stays/the-log-house" className="explore-button1">
                    Know More <span className="arrow1">→</span>
                  </a>
                </div>
                </div>
              
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
              <div className="propertybox1">
                <img src={horizonvilla} className="HSPick1" alt="" />
                <div className="propertybox1-content">
                  <Link to={`/stays/the-horizon-villa`}>
                    <h2 className="text-white">The Horizon Villa</h2>
                  </Link>
                  <ul>
                    <li>4 Bedrooms </li>
                    <li>Professional Chef </li>
                    <li>Wi-Fi/TV </li>
                    <li>180 Degree Valley Views </li>
                  </ul>
                  <ul>
                    <li>Huge Sit-Out Area </li>
                    <li>Workspace </li>
                    <li>Bonfire/BBQ</li>
                  </ul>
                  <div className="Proding2">
                  <a href="/stays/the-horizon-villa" className="explore-button1">
                    Know More <span className="arrow1">→</span>
                  </a>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row" id="propty2">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
              <div className="propertybox1">
                <img src={TheSilvertonChalet} className="HSPick1" alt="" />
                <div className="propertybox1-content">
                  <Link to={`/stays/the-silverton-chalet`}>
                    <h2 className="text-white">The Silverton Chalet </h2>
                  </Link>
                  <ul>
                    <li>3 Bedrooms/ 4 Beds  </li>
                    <li>3 Sit Out Areas  </li>
                    <li>Professional Chef </li>
                    <li>Wi-Fi/TV  </li>
                  </ul>
                  <ul>
                    <li>Air Conditioning   </li>
                    <li>Bonfire/BBQ</li>
                    <li>Modernized Kitchen </li>
                  </ul>
                  <div className="Proding2">
                  <a href="/stays/the-silverton-chalet" className="explore-button1">
                    Know More <span className="arrow1">→</span>
                  </a>
                </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
              <div className="propertybox1 ">
              
                <img src={sicottage} className="HSPick1" alt="" />
                <div className="propertybox1-content">
                  <Link to={`/stays/the-si-cottage`}>
                    <h2 className="text-white">The Si Cottage </h2>{" "}
                  </Link>
                  <ul>
                    <li>3 Bedrooms </li>
                    <li>Open Air Deck </li>
                    <li>Living Room</li>
                    <li>Bonfire/BBQ</li>
                  </ul>
                  <ul>
                    <li>Wi-Fi/TV </li>
                    <li>Modernized Kitchen </li>
                    <li>Scenic Garden</li>
                  </ul>
                  <div className="Proding2">
                  <a href="/stays/the-si-cottage" className="explore-button1">
                    Know More <span className="arrow1">→</span>
                  </a>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row" id="propty2">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
            <div className="propertybox1 ">
              
              <img src={Pinkmangolia} className="HSPick1" alt="" />
              <div className="propertybox1-content">
                <Link to={`/stays/the-pink-magnolia-cottage`}>
                  <h2 className="text-white">The Pink Magnolia Cottage </h2>
                </Link>
                <ul>
                  <li>4 Bedrooms </li>
                  <li>Wi-Fi/TV </li>
                  <li>Free Parking</li>
                  <li>Heaters/Geysers  </li>
                </ul>
                <ul>
                  <li>Bonfire/BBQ</li>
                  <li>Air Conditioning</li>
                  <li>Modernized Kitchen </li>
                </ul>
                <div className="Proding2">
                <a href="/stays/the-pink-magnolia-cottage" className="explore-button1">
                  Know More <span className="arrow1">→</span>
                </a>
              </div>
              </div>
            </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
            <div className="propertybox1 ">
              
              <img src={GardenCottage} className="HSPick1" alt="" />
              <div className="propertybox1-content">
                <Link to={`/stays/the-garden-cottage`}>
                  <h2 className="text-white">The Garden Cottage </h2>
                </Link>
                <ul>
                  <li>3 Bedrooms  </li>
                  <li>Modernized Kitchen   </li>
                  <li>Free Parking  </li>
                  <li>Bonfire/BBQ</li>
                </ul>
                <ul>
                  <li>Heaters/Geysers  </li>
                  <li>Air Conditioning </li>
                  <li>Private Terrace </li>
                </ul>
                <div className="Proding2">
                <a href="/stays/the-garden-cottage" className="explore-button1">
                  Know More <span className="arrow1">→</span>
                </a>
              </div>
              </div>
            </div>
              </div>
          </div>
          <div className="row" id="propty2">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
            <div className="propertybox1 ">
              
              <img src={minihorizon} className="HSPick1" alt="" />
              <div className="propertybox1-content">
                <Link to={`/stays/the-mini-horizon`}>
                  <h2 className="text-white">The Mini Horizon  </h2>
                </Link>
                <ul>
                  <li>1 Bedroom   </li>
                  <li>Modernized Kitchen   </li>
                  <li>Wi-Fi/TV  </li>
                  <li>Air Conditioning </li>
                </ul>
                <ul>
                  <li>Free Parking </li>
                  <li>Heaters/Geysers </li>
                  <li>Bonfire/BBQ  </li>
                </ul>
                <div className="Proding2">
                <a href="/stays/the-mini-horizon" className="explore-button1">
                  Know More <span className="arrow1">→</span>
                </a>
              </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="client-reviews">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-md-12">
              <h5 className="new-sub-heading">
                Delighted words that keep our wings soaring
              </h5>
              <h2 className="new-heading">Rave reviews </h2>
            </div>
          </div>
        </div>
      </section> */}
      {/* <Testimonials /> */}
{/* <Testimonials /> */}

      {/* <Instapost /> */}
      <section className="lastcta">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-12 col-md-12 col-12">
              <div className="outboxer">
                <h5 className="new-sub-heading">Book Your Dream Escape!  </h5>
                <h3 className="new-heading-2">
                Getting bored with the ordinary and craving a change of scenery? It’s time to book the getaway you’ve been eyeing. Whether you’re looking for a cozy cottage, a lavish villa, or a gorgeous chalet, we’ve got the picture-perfect home for your dream holiday.
                </h3>
                <div className="Proding2 mt-4">
                    <a href="/Register" className="explore-button1">
                    Sign Up <span className="arrow1">→</span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Stayssections;
