import React from "react";
import SearchList3 from "../../components/searchlist/searchlist3";
import SimpleSlider from "../../components/searchlist/newslider";
import newpost from "../../../src/img/newimg/newpost.png";
import image1 from "../../../src/img/newimg/design1.png";
import newblog from "../../../src/img/newimg/image (3).jpg";
import ExploreSlider from "../../components/searchlist/exploreslider";
import Doubleslider from "../../components/newsite/Doubleslider";
import Doubleslidermobile from "../../components/newsite/Doubleslidermobile";
import mainbnr from "../../img/mainbnr.webp";
import Updatedpropertyslider from "../../components/my-components/Updated-property-slider";
import Testimonials from "../../components/newsite/testimonials";
import Sliderproperties from "../../components/Homestay-components/Sliderproperties";
import { FaArrowRightLong } from "react-icons/fa6";
import Allstayslider from "../../components/my-components/Allstayslider";
import Navbar4 from "../../components/navbar/Navbar4";

function homenew() {
  return (
    
    <div>
         <div className="main-header-2">
        <Navbar4 />
      </div>
      <div className="desk-sec">
        <Sliderproperties />
      </div>

      {/* <section className="mainbnner" style={{
        backgroundImage: "url(" + mainbnr + ")",
      }} >
        <div className="home-banner-gradient"></div>
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-md-12 col-12">
              <div className="mybnr1">
                <h1>Somany mornings</h1>
                <p className="tranquility text-white">
                Stay in India’s most luxurious homes 

                </p>
                <div className="bnr-from">
                  <SearchList3 />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="newproslider-sec" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-xl-10 col-lg-10 col-md-12 col-12">
            <div className="content-suites">
                <h2 className="wordCarousel">
                  <span>Find your luxury escape: </span>
                  <span>
                  <div>
                    {/* Use classes 2,3,4, or 5 to match the number of words */}
                    <ul className="flip5">
                      <li>Cottages</li>
                      <li>Villas</li>
                      <li>Chalets</li>
                      <li>Cottages</li>
                      <li>Villas</li>
                      <li>Chalets</li>
                    </ul>
                  </div>
                  </span>
                </h2>
                {/* <div className="desk-head">
                <h2>Cottages.Villas.Chalets</h2>
                </div> */}
                <p>We handpick the best properties so that you don't have to.</p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-2 col-lg-2 col-md-12 col-12">
              <div className="seevilla">
                <a href="/villa-collections">View All<span><FaArrowRightLong /></span></a>
              </div>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-xxl-12 col-lg-12 col-md-12 col-12">
              {/* <div className="content-suites">
              <h2>Cottages.Villas.Chalets</h2>
              <p>We handpick the best properties so that you don't have to.</p>
            </div> */}
              <Allstayslider />
              {/* <Updatedpropertyslider /> */}
            </div>
          </div>
        </div>
      </section>
{/* <section className="everyone">
<div className="container"  id="Something">
  <div className="row">
    <div className="col-xxl-12 col-md-12 col-12">
      <h3>Something for everyone</h3>
        </div>
</div>
  <div className="row" >
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12">
      <div className="card01">
        <h4>For Families</h4>
        <p>3-10 guests</p>
      </div>
    </div>
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12">
      <div className="card01">
        <h4>For Couples</h4>
        <p>Perfect for 2 guests</p>
      </div>
    </div>
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12">
      <div className="card01">
        <h4>For Corporates</h4>
        <p>Tailored for team meets</p>
      </div>
    </div>
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12">
      <div className="card01">
        <h4>Show me the best</h4>
        <p>I’m flexible</p>
      </div>
    </div>
  </div>

  </div>
</section> */}
      {/* <section className="mynewbnner1">
        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
          <div className="row">
            <div className="contec1">
  
              <img  src={tinyimg} className="tinyimg" alt="tinyimg" />
              <p className="figmabnrtext1">
                Your gateway to extraordinary staycations{" "}
              </p>
              <div className="Proding1">
                <a href="/villa-collections" className="explore-button1">
                  Explore Now <span className="arrow1">→</span>
                </a>
              </div>
            </div>
          </div>
          <div className="overlay"></div>
          <video
            src={Video123}
            className="Figmabnr1"
            autoPlay
            loop
            muted
            playsInline
          ></video>

        </div>
      </section> */}

      {/* <section className="seachsection" id="booking1">
        <div className="container-fluid topform">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="list-outer-search1">
                <SearchList3 />
            
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <SimpleSlider /> */}
      {/* <SecondSlider /> */}

      {/* <BigSlider /> */}

      <Doubleslider />
      <Doubleslidermobile />
      {/* <CustomSwiper /> */}
      <section className="new-one">
        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
          <div className="new-two">
            <h5>Captured Moments </h5>
            <h2 className="text-white">
              Ready To Make Your <br />Own Memories?
            </h2>
            <img src={newpost} alt="" className="Memories"/>
          </div>

        </div>
      </section>

      {/* <Newtab /> */}
      <section className="reviews-sec">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12 col-12">
              <h2>Don’t Just Take Our Word for It </h2>
              <p>Hear from those who’ve stayed with us </p>
              <Testimonials />
            </div>
          </div>
        </div>
      </section>
  
      {/* <Instapost />
      <InstagramPosts />
      <Review2slider /> */}
      <ExploreSlider />
      {/* <div className="container">
        <div className="last-box">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="imagesectionsdf">
                <img src={newblog} alt="" />
              </div>
            </div>

            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="text-sectionsdf">
                <h2>
                Book Your   <br></br>Dream Escape!
                </h2>
                <p>
                Getting bored with the ordinary and craving a change of scenery? It’s time to book the getaway you’ve been eyeing. Whether you’re looking for a cozy cottage, a lavish villa, or a gorgeous chalet, we’ve got the picture-perfect home for your dream holiday.
                </p>

                <div className="Proding2">
                  <a href="#booking1" className="explore-button1">
                    Book Now <span className="arrow1">→</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <section className="lastcta">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-12 col-md-12 col-12">
              <div className="outboxer">
                <h5 className="new-sub-heading">Book Your Dream Escape!  </h5>
                <h3 className="new-heading-2">
                Getting bored with the ordinary and craving a change of scenery? It’s time to book the getaway you’ve been eyeing. Whether you’re looking for a cozy cottage, a lavish villa, or a gorgeous chalet, we’ve got the picture-perfect home for your dream holiday.
                </h3>
                <div className="Proding2 mt-4">
                    <a href="/Register" className="explore-button1">
                    Sign Up <span className="arrow1">→</span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default homenew;
