import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import Home from "./pages/home/Home";
import Hotel from "./pages/hotel/Hotel";
import List from "./pages/list/List";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import Contact from "./pages/contact_us/Contact";
import ForgotPassword from "./pages/forgot-password/ForgotPassword";
import ResetPassword from "./pages/reset-password/ResetPassword";
import About from "./pages/About/About";
import Stays from "./pages/Stays/Stays";
import Promotions from "./pages/offerpage/Promotions"
import Hotelsearch from "./pages/Hotelsearch/Hotelsearch";
import Images from "./pages/images/images";
import MyBooking from "./components/MyBooking/my-booking";
import MyAccount from "./components/myaccounts/my-account";
import NotFoundPage from "./pages/404Page/Notfound";
import Rooms from "./pages/Rooms/Rooms";
import Privacypolicy from "./pages/Privacy-policy";
import Termsconditions from "./pages/Terms-conditions";
import Success from "./pages/success/success";
import Cancellationpolicy from "./pages/cancellation-policy";
import Myhome from "./pages/home/Myhome";
import Villacollection from "./pages/Stays/Villacollection";
import Collaborations from "./pages/Collaborations";
import HomeNew from "./pages/home/home-new";
import Footer3 from "./components/footer/Footer3";
import Navbar4 from "./components/navbar/Navbar4";
import MobileHead from "./components/navbar/mobile-head";
import Refundpolicy from "./pages/refund-policy";
function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <BrowserRouter>
      {/* <div className="mobile-head">
        <MobileHead />
      </div> */}
      {/* <div className="main-header-2">
        <Navbar4 />
      </div> */}
      <Routes>
      <Route path="/refund-policy" element={<Refundpolicy />} />
        <Route path="/Myhome" element={<Myhome />} />
        <Route path="/" element={<HomeNew />} />
        <Route path="/success" element={<Success />} />
        <Route path="/Cancellation-policy" element={<Cancellationpolicy />} />
        <Route path="/Terms-conditions" element={<Termsconditions />} />
        <Route path="/Privacy-policy" element={<Privacypolicy />} />
        <Route path="/Rooms" element={<Rooms />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/my-account" element={<MyAccount />} />
        <Route path="/my-booking" element={<MyBooking />} />
        <Route path="/book-your-stay" element={<Hotelsearch />} />
        <Route path="/Promotions" element={<Promotions />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/villa-collections" element={<Villacollection />} />
        <Route path="/special-events" element={<Collaborations />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/About" element={<About />} />
        <Route path="/stays" element={<List />} />
        <Route path="/register" element={<Register />} />
        <Route path="/stays/:id" element={<Hotel />} />
        <Route path="/login" element={<Login />} />
        {/* <Route path="/reset-password" element={<ForgotPassword />} /> */}
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:id/" element={<ResetPassword />} />
        <Route path="/images" element={<Images />} />
      </Routes>
      <Footer3 />
    </BrowserRouter>
  );
}

export default App;
